import { Card, Spinner, Tab, Tabs, Link, Icon, Grid, AllIconsType } from "@platform-ui/design-system";
import React, { useEffect, useState } from "react";
import BasicTemplate from '../BasicTemplate/BasicTemplate';
import CustomTab from "./IntegrationAppDetails/components/CustomTab";
import { IADContextProvider, initialState as IADInitialState, IADTabPanel, IADTitle } from './IntegrationAppDetails';
import { IADState } from './IntegrationAppDetails/state';
import IntegrationAppDetailsOverview from "./IntegrationAppDetailsOverview";
import $ from 'jquery';
import { useFlipperHook } from '../Tables/helpers';
import {Box} from "@mui/material";
import { AmplitudeEvents, useAmplitude } from "../Amplitude/hooks/useAmplitude";
import Connect from '../Connect/Connect';
import { Execution } from '../Execution';
import Flipper from '../Connect/Flipper';
import { ToastProvider } from '../ToastProvider';

const TABS_WITH_NO_BUTTON_GROUP = [
  'overview',
  'execution',
  'NetSuiteGL/execution',
  'NetSuiteGL/solutionInstances',
  'NetSuiteERP/execution',
  'NetSuiteERP/solutionInstances',
  'Snowflake/connectionSettings',
  'ZDP/authentication',
  'ZDP/syncSettings',
  'Workday/execution'
];
const REDIRECT_TABS = [
  'HubSpot/configureTray',
  'Salesforce/configureTray',
  'ZDP/metrics'
];

interface CustomIcon {
  icon: AllIconsType;
  tooltip?: string;
}

function IntegrationAppDetailsView(props: any) {
  // NOTE(Xander): In Billing, we want to replace the Marketplace Header in the sidebar with Integration Hub, but we can't do that in time for ZKO, so we hack
  // TODO(Xander): Remove this after we make an MR for Billing to change the Marketplace header
  const [ihToMarketplace, loading] = useFlipperHook('ih_to_marketplace');
  if (ihToMarketplace) {
    $(document).ready(function() {
      $("button#Marketplace-header div.MuiAccordionSummary-content span.MuiTypography-root span").text('Integration Hub');
    });
  }

  const [tabs, setTabs] = useState(0);
  const [selectedTab, setSelectedTab] = useState('');
  const [initialState, setInitialState] = useState(IADInitialState);
  const [fetching, setFetching] = useState(true);
  const [showButtonGroup, setShowButtonGroup] = useState(false);
  const [configurationRedirect, setConfigurationRedirect] = useState('');
  const [zdpMetricsRedirect, setZdpMetricsRedirect] = useState('');
  const [customIcon, setCustomIcon] = useState<CustomIcon>({ icon: 'info', tooltip: ''});
  const [showTabIcon, setShowTabIcon] = useState('');
  const { logEvent } = useAmplitude();

  // TODO(Xander): Replace props.locale with a current_user or tenant locale check.
  let locale = !!props.app.title_translations?.[props.locale] ? props.locale : 'en'
  let titles = props.app.title_translations[locale]
  let meta = props.app.meta
  let rawTabList = props.tabList ?? []
  let tabList = rawTabList.filter((tab: string) => {
    return !REDIRECT_TABS.includes(tab)
  })
  let uiLayout = props.app.ui_layout
  let icon = props.app?.service_data?.icon || props.icon
  let images = props.images
  let overview = props.overview

  logEvent(AmplitudeEvents.PAGE_VIEWED, {object: 'integrationhub' + titles.title.toLowerCase().replaceAll(' ', ''), view: 'detail', screenHeight: window.innerHeight, screenWidth: window.innerWidth})

  let hubspot = rawTabList.filter((tab: string, index: number) => {
    let currentTab = uiLayout.tabs[index][tab]
    return currentTab.enabled && tab === 'HubSpot/configureTray'
  }).length > 0

  let salesforce = rawTabList.filter((tab: string, index: number) => {
    let currentTab = uiLayout.tabs[index][tab]
    return currentTab.enabled && tab === 'Salesforce/configureTray'
  }).length > 0

  let zdpMetrics = rawTabList.filter((tab: string, index: number) => {
    let currentTab = uiLayout.tabs[index][tab]
    return currentTab.enabled && tab === 'ZDP/metrics'
  }).length > 0

  let readOnly = props.readOnly ?? false;
  
  const openMetricsNewTab = () => {
    window.open(zdpMetricsRedirect, '_blank');
  }

  const showCustomIcon = (tabName, icon, tooltip = '') => {
    setCustomIcon({
      icon,
      tooltip
    });
    setShowTabIcon(tabName);
  };
  
  const customIconComponent = (
    <Grid item>
      <Icon body={customIcon.icon} tooltip={customIcon.tooltip} fontSize='small'/>
    </Grid>
  );
  
  const getTabName = (input: string) => {
    const index = input.indexOf('/');
    let result = index !== -1 ? input.substring(index + 1) : input;
    result = result.replace(/([a-z])([A-Z])/g, '$1_$2').toLowerCase();
    return result;
  };
  
  const handleTabChange = (tabName: string) => {
    if (tabName === 'overview' || !tabName) {
      updateURL({ tabname: 'overview' });
      setSelectedTab(tabName);
      setTabs(0);
    } else {
      setTabButtonGroup(tabName);
      const tabIndex = tabList.findIndex(tab => getTabName(tab) === tabName) + 1;
      if (tabName) {
          updateURL({ tabname: tabName });
      }
      setTabs(tabIndex);
      setSelectedTab(tabName);
    }
    
};

const setTabButtonGroup = (tabName: string) => {
  const tabFromList = tabList.find(tab => getTabName(tab) === tabName);
  if (TABS_WITH_NO_BUTTON_GROUP.includes(tabFromList)) {
    setShowButtonGroup(false);
  } else {
    setShowButtonGroup(true);
  }
}

useEffect(() => {
  const handleTabSelectionFromURL = () => {
    const searchParams = new URLSearchParams(window.location.search);
    let tabParam = searchParams.get("tabname");
    if (!tabParam || !tabList.some(tab => getTabName(tab) === tabParam)) {
      setShowButtonGroup(false);
      tabParam = "overview";
      const queryParams = { tabname: tabParam };
      updateURL(queryParams);
    }
    setTabButtonGroup(tabParam)
    const tabIndex = tabList.findIndex(tab => getTabName(tab) === tabParam) + 1;
    setTabs(tabIndex);
    setSelectedTab(tabParam);
  };

  handleTabSelectionFromURL();
}, [tabList]);

const updateURL = (queryParams: Record<string, string>) => {
    const searchParams = new URLSearchParams(window.location.search);
    Object.entries(queryParams).forEach(([key, value]) => {
        searchParams.set(key, value);
    });
    const newURL = `${window.location.pathname}?${searchParams.toString()}`;
    window.history.pushState({}, '', newURL);
};

  useEffect(() => {
    if (readOnly) {
      setFetching(false);
    } else {
      // Note(Duc): Fetch settings from integration apps settings table, but could be fetched directly from Workflow in the future.
      const csrf = document.querySelector('meta[name=\'csrf-token\']').getAttribute('content');
      const options = {
        headers: new Headers({
          'Accept': 'application/json',
          'Content-type': 'application/json',
          'X-CSRF-Token': csrf
        })
      };
      const prefix = window.location.href.includes('admin') ? '/admin' : '';

      let path = `${prefix}/integration_apps_settings/${props.app.id}/${props.tenantId}.json`;
      let windmillPath = `${prefix}/integration_apps/${props.tenantId}/windmill_run`;
      if (!window.location.host.includes('connect') && !window.location.host.includes('localhost')) {
        path = '/services/connect' + path;
        windmillPath = '/services/connect' + windmillPath;
      }
      
      if (uiLayout.tabs.length === 0 && !window.location.host.includes('connect') && !window.location.host.includes('localhost')){
        let enabled = Flipper.enabled('CRM_auto_enable', 'IntegrationApp', props.app.id);
        Flipper.enabled('CRM_auto_enable', 'IntegrationApp', props.app.id)
          .then((enabled) => {
            if(enabled){
              const connect: Connect = (window as any).connect;
              const tenantId = (connect.tenant as any).tenant_id;
              let zdp_enabled = false
              let package_installed = false

              window.fetch('/platform/gateway-proxy-v2/datacon/tenanthub-proxy/zdp-connection-info', options)
                .then(response => {
                  if (!response.ok) {
                      throw new Error(`HTTP error! status: ${response.status}`);
                  }
                  return response.json();
              }).then(zdpConnectionInfo => {
                  if(zdpConnectionInfo.code != 404){
                    zdp_enabled = true
                  }
                }).then(() => {
                  window.fetch(`/platform/gateway-proxy-v2/datacon/package/versions/tenants/${tenantId}`, options)
                    .then(response => response.json())
                    .then(packages => {
                      if(packages.z360Version != null){
                        package_installed = true
                      }
                    }).then(() => {
                  // NOTE(Michael): if both ZDP is enabled and we can detect that the package is installed, we will add the force param and reload the page
                    if (zdp_enabled && package_installed){
                      let currentUrl = window.location.href;
                      let newParam = 'forceCreation=true';

                      if (currentUrl.includes('?')) {
                          // URL already has parameters
                          currentUrl += '&' + newParam;
                      } else {
                          // URL has no parameters
                          currentUrl += '?' + newParam;
                      }
                      window.location.href = currentUrl;
                    }
                    
                  })
                })
            }
          });
      }

      window.fetch(path + window.location.search, options)
        .then(response => response.json())
        .then(integrationAppsSetting => {
          let state: IADState = {
            createOrUpdate: 'create',
            settingsUrl: path,
            production: !!integrationAppsSetting?.production,
            enablePrivateWorkflow: !!integrationAppsSetting?.enablePrivateWorkflow,
            enableTaskSummary: !!integrationAppsSetting?.enableTaskSummary,
            inbound: !!integrationAppsSetting?.inbound,
            currentUser: integrationAppsSetting?.currentUser,
            tabDetails: uiLayout?.tabs || [],
            netsuiteAdmin: props.netsuiteAdmin,
            netsuiteNewLayout: integrationAppsSetting?.netsuiteNewLayout,
            external_id: integrationAppsSetting?.external_id,
            workflow_template: props.app?.service_data?.workflow_template || {},
            workflow_template_id: props.app?.service_data?.workflow_template_id,
            workflow_auto_refresh_frequency: props.app?.service_data?.workflow_auto_refresh_frequency,
            workday_mappings: props.app?.service_data?.workday_mappings,
            default_mappings: { summary: props.app?.service_data?.summary_default_mappings, detail: props.app?.service_data?.detailed_default_mappings },
            update_workflow: !!props.app?.service_data?.update_workflow,
            envRestEndpoint: integrationAppsSetting?.envRestEndpoint,
            workflow_template_name: props.app?.service_data?.workflow_template_name,
            windmillPath
          };
          if(props.app.redirect_url){
            setConfigurationRedirect(props.app.redirect_url)
          }
          if (integrationAppsSetting && integrationAppsSetting.id !== null) {
            const originalAuthentication = integrationAppsSetting?.settings_hash?.authentication || {};
            const originalSettings = integrationAppsSetting?.settings_hash?.settings || {};
            const originalEmailNotifications = integrationAppsSetting?.settings_hash?.emailNotifications || {};
            const settingsHash = integrationAppsSetting?.settings_hash || {};
            settingsHash['filteredFieldMapping'] = settingsHash?.fieldMapping || {};
            state = {
              ...state,
              settingsHash: settingsHash,
              lastUpdatedBy: integrationAppsSetting?.last_updated_by || '',
              updatedAt: integrationAppsSetting?.updated_at || '',
              active: integrationAppsSetting?.status === 'Active',
              createOrUpdate: 'update',
              originalAuthentication: {...originalAuthentication},
              originalSettings: {...originalSettings},
              originalEmailNotifications: {...originalEmailNotifications}
            };
            if (tabList.includes('authentication')) {
              setTabs(tabList.indexOf('authentication') + 1)
              setShowButtonGroup(true);
            }
          } else {
            state = {...state, ...initialState};
          }
          setInitialState(state);
        })
        .then(() => {
          if (hubspot) {
            let getHubspotPath = `${prefix}/tools/connectors/${props.tenantId}/hubspot.json`;
            if (!window.location.host.includes('connect') && !window.location.host.includes('localhost')) {
              getHubspotPath = '/services/connect' + getHubspotPath;
            }

            window.fetch(getHubspotPath, options)
              .then(response => response.json())
              .then(hubspotConnector => {
                setConfigurationRedirect(hubspotConnector.path);
              });
          } else if (salesforce) {
            let getSalesforcePath = `${prefix}/tools/connectors/${props.tenantId}/salesforce.json`;
            if (!window.location.host.includes('connect') && !window.location.host.includes('localhost')) {
              getSalesforcePath = '/services/connect' + getSalesforcePath;
            }

            window.fetch(getSalesforcePath, options)
              .then(response => response.json())
              .then(salesforceConnector => {
                setConfigurationRedirect(salesforceConnector.path);
              });
          } else if (zdpMetrics) {
            setZdpMetricsRedirect(`${prefix}/platform/telemetry/data-connect`);
          }
        })
        .catch((err) => Connect.log(err))
        .finally(() => setFetching(false));
    }
  }, []);

  return (
    <ToastProvider>
      {
        fetching ?
        <Spinner /> :
        <IADContextProvider initialState={initialState}>
          <Box paddingBottom={readOnly && 1.5}>
            <IADTitle showBackButton={true} icon={icon} title={titles.title} showButtonGroup={!readOnly && showButtonGroup} />
          </Box>
          {
            !readOnly &&
              <Tabs
                  a11yLabel="tab"
                  value={selectedTab}
                  dsOnChange={
                    (event, value) => {
                      handleTabChange(value);
                    }
                  }
                  e2e="integration-app-tabs"
              >
                  <Tab
                      id="overview"
                      e2e="overview"
                      label="Overview"
                      aria-controls={""}
                      key="tab-0"
                      value="overview"
                  />
                {
                  // TODO(Xander): Add the localized tab title back after updating the form to allow you to set it
                  tabList.map((tab, index) => {
                    let currentTab = uiLayout.tabs[index][tab]
                    if (currentTab.enabled) {
                      const tabName = getTabName(tab);
                      return (
                        <Tab
                          id={tabName}
                          e2e={tabName}
                          value={tabName}
                          label={
                            <Grid container direction='row' justify='center' spacing={1}>
                              {showTabIcon === currentTab.titleTranslations[locale].tabName && customIconComponent}
                              <Grid item>
                                {currentTab.titleTranslations[locale].tabName}
                              </Grid>
                            </Grid>}
                          aria-controls={""}
                          key={`tab-${index + 1}`}
                          {...{sx: { display: currentTab.hidden && !props.netsuiteAdmin ? 'none' : '' }}}
                        />
                      )
                    }
                  })
                }
                {
                  configurationRedirect &&
                    <Link href={configurationRedirect} underline="none" color="initial">
                        <Tab
                            id="redirect"
                            e2e="redirect-configure"
                            label="Configure"
                            value="configure"
                            aria-controls={""}
                            key="redirect-tab"
                        />
                    </Link>
                }
                {
                  zdpMetricsRedirect &&
                    <Link dsOnClick={openMetricsNewTab} underline="none" color="initial">
                        <Tab
                            id="redirect"
                            e2e="redirect-metrics"
                            label="Metrics"
                            value="metrics"
                            aria-controls={""}
                            key="redirect-tab"
                        />
                    </Link>
                }
              </Tabs>
          }
          {
            <IADTabPanel key='tab-panel-0' tabs={tabs} index={0} id='integration-app-overview'>
              <IntegrationAppDetailsOverview title={titles.title} description={overview} images={images} />
            </IADTabPanel>
          }
          {
            !readOnly &&
            tabList.map((tab, index) => {
            let currentTab = uiLayout.tabs[index][tab]
            if (currentTab.enabled) {
              switch (tab) {
                // Pre-defined tabs
                case 'authentication':
                  return (
                    <IADTabPanel key={`tab-panel-${index+1}`} tabs={tabs} index={index+1}>
                      <BasicTemplate
                        id={tab + "-card"}
                        header={currentTab?.title_translations?.[locale]?.['cardHeader'] || 'Authentication Status'}
                        showHeader={currentTab?.showHeader == null ? true : currentTab.showHeader}
                        lastUpdatedBy={initialState.lastUpdatedBy}
                        lastUpdatedDate={initialState.updatedAt}
                        fieldGroups={currentTab.fieldGroups}
                        locale={locale}
                        showTestConnection={false}
                        showRefreshButton={false}
                        tabName={tab}
                      />
                    </IADTabPanel>
                  )
                case 'settings':
                  return (
                    <IADTabPanel key={`tab-panel-${index+1}`} tabs={tabs} index={index+1}>
                      <BasicTemplate
                        id={tab + "-card"}
                        header={currentTab?.title_translations?.[locale]?.['cardHeader'] || 'Connector Settings'}
                        showHeader={currentTab?.showHeader == null ? true : currentTab.showHeader}
                        lastUpdatedBy={initialState.lastUpdatedBy}
                        lastUpdatedDate={initialState.updatedAt}
                        fieldGroups={currentTab.fieldGroups}
                        locale={locale}
                        tabName={tab}
                      />
                    </IADTabPanel>
                  )
                case 'fieldMapping':
                  return (
                    <IADTabPanel key={`tab-panel-${index+1}`} tabs={tabs} index={index+1}>
                      <BasicTemplate
                        id={tab + "-card"}
                        header='Field Mapping'
                        showHeader={currentTab?.showHeader == null ? true : currentTab.showHeader}
                        lastUpdatedBy={initialState.lastUpdatedBy}
                        lastUpdatedDate={initialState.updatedAt}
                        fieldGroups={currentTab.fieldGroups}
                        locale={locale}
                        tabName={tab}
                      />
                    </IADTabPanel>
                  )
                case 'metrics':
                  return (
                    <IADTabPanel key={`tab-panel-${index+1}`} tabs={tabs} index={index+1}>
                      <Card
                        id={tab + "-card"}
                        header={currentTab.titleTranslations[locale].cardHeader}
                        body={currentTab.descriptionTranslations[locale].description}
                      />
                    </IADTabPanel>
                  )
                case 'execution':
                  return (
                    <IADTabPanel key={`tab-panel-${index+1}`} tabs={tabs} index={index+1}>
                      <Execution connectorName={titles?.title?.toLowerCase()?.replaceAll(' ', '') || 'DefaultConnectorName'} />
                    </IADTabPanel>
                  )
                case tab.match(/^custom.*/i)?.input:
                  return (
                    <IADTabPanel key={`tab-panel-${index+1}`} tabs={tabs} index={index+1}>
                      <BasicTemplate
                        id={tab + "-card"}
                        header={currentTab?.title_translations?.[locale]?.['cardHeader'] || 'Generic Custom Tab'}
                        showHeader={currentTab?.showHeader == null ? true : currentTab.showHeader}
                        lastUpdatedBy={initialState.lastUpdatedBy}
                        lastUpdatedDate={initialState.updatedAt}
                        fieldGroups={currentTab.fieldGroups}
                        locale={locale}
                        tabName={tab}
                      />
                    </IADTabPanel>
                  )
                case tab.match(/^iframe.*/i)?.input:
                  return (
                    <IADTabPanel key={`tab-panel-${index+1}`} tabs={tabs} index={index+1}>
                      <iframe
                        id={`${tab}-iframe-${index+1}`} src={currentTab['iframe']+'?meta='+btoa(JSON.stringify(window.connect))} scrolling='yes' seamless={'seamless' as any} style={{ display: 'block', width: '100%', height: '100vh' }}
                      />
                    </IADTabPanel>
                  )
                default:
                  // Custom Tabs
                  // To add a custom tab, create a new Typescript file in /Connectors/CustomTabs
                  // and update IntegrationAppDetails/components/CustomTab.tsx with a new case for the component
                  return (
                    <IADTabPanel key={`tab-panel-${index+1}`} tabs={tabs} index={index+1}>
                      <CustomTab
                        name={tab}
                        locale={locale}
                        tabDetails={currentTab}
                        lastUpdatedBy={initialState.lastUpdatedBy}
                        lastUpdatedDate={initialState.updatedAt}
                        tenantId={props.tenantId}
                        customIcon={showCustomIcon}
                      />
                    </IADTabPanel>
                  )
              }
            }
          })}
        </IADContextProvider>
      }
    </ToastProvider>
  );
}

export default IntegrationAppDetailsView;
